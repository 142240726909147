<template>
  <a-spin :spinning="isLoadingFull">
    <div>
      <portal to="header-toolbar">
        <div class="p-stock-header" v-show="!isLoadingFull">
          <a-button
            class="header-btn-save"
            size="large"
            type="primary"
            :loading="isLoadingUpdate"
            @click="handleUpdate"
          >
            Simpan Perubahan
          </a-button>
        </div>
      </portal>
      <a-row :gutter="24" v-show="!isLoading">
        <a-col :span="14">
          <a-row>
            <div class="stock-container">
              <div style="display: flex; justify-content: space-between; margin-bottom: 24px">
                <div class="variant-name">{{ storeData.name }}</div>
                <div>
                  <a-select
                    v-model="variantData.is_active"
                    size="large"
                    class="p-select-status-update-stock"
                    :class="{ 'not-active-status': variantData.is_active === 'Tidak Aktif' }"
                  >
                    <a-select-option value="Aktif">
                      Aktif
                    </a-select-option>
                    <a-select-option value="Tidak Aktif">
                      Tidak Aktif
                    </a-select-option>
                  </a-select>
                </div>
              </div>
              <div style="display: flex">
                <div style="flex: 1">
                  <div class="label-field">Harga</div>
                  <div style="display: flex">
                    <div style="flex: 1">
                      <a-input
                        v-model="variantData.price"
                        size="large"
                        placeholder="Misal: 1000"
                        @change="checkFormValid()"
                        @keypress="isDecimal($event)"
                      >
                        <span slot="prefix">
                          <span
                            class="label-field"
                            style="padding-right: 8px; border-right: 1px solid #e5e5e6;"
                          >
                            Rp
                          </span>
                        </span>
                      </a-input>
                    </div>
                  </div>
                </div>
                <div style="width: 24px"></div>
                <div style="flex: 1">
                  <div class="label-field">Jumlah Stok</div>
                  <a-input
                    v-model="variantData.quantity"
                    size="large"
                    placeholder="Misal: 1000"
                    @change="checkFormValid()"
                    @keypress="isNumber($event)"
                  />
                </div>
              </div>
            </div>
          </a-row>
          <a-row>
            <div class="variant-history">
              <div style="display: flex; justify-content: space-between; margin-bottom: 12px;">
                <span class="title">Daftar Perubahan Stok</span>
                <a-select
                  :value="currentMthYr"
                  size="large"
                  style="width: 150px"
                  @select="handleMthYrChange"
                >
                  <a-select-option v-for="mthYr in listMonthYear" :key="mthYr" :value="mthYr">
                    {{ mthYr }}
                  </a-select-option>
                </a-select>
              </div>
              <div>
                <a-table
                  :columns="columnTable"
                  :data-source="getDailyInbentoriesComputed"
                  rowKey="id"
                  :locale="locale"
                  size="middle"
                  :pagination="false"
                  :loading="isTableLoading"
                />
              </div>
            </div>
          </a-row>
        </a-col>
        <a-col :span="10">
          <div class="variant-info" v-if="variantData && variantData.variant">
            <div style="display: flex">
              <div>
                <img
                  v-if="
                    variantData.variant.photos &&
                      variantData.variant.photos.length > 0 &&
                      variantData.variant.photos[0].variant_photo
                  "
                  :style="{
                    backgroundImage: `url(${variantData.variant.photos[0].variant_photo})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                  }"
                  width="100"
                  height="55"
                />
                <div v-else :style="imgPreviewStyle">
                  <a-icon style="font-size: 32px; color: #b2b3b5" type="picture" />
                </div>
              </div>
              <div style="margin-left: 16px">
                <div style="font-size: 16px; font-weight: 700; color: #0146ab;">
                  {{ variantData.variant.name }}
                </div>
                <div
                  style="font-size: 14px; font-weight: normal;
                  color: #7f8184; margin-top: 4px;"
                >
                  {{ variantData.variant.sku }}
                </div>
              </div>
            </div>
            <a-divider style="margin: 18px 0;" />
            <div>
              <div class="label">Deskripsi</div>
              <div class="sub-label">{{ variantData.variant.description }}</div>
            </div>
            <a-divider style="margin: 18px 0;" />
            <div>
              <div class="label">Referensi Produk</div>
              <div class="sub-label">
                {{ variantData.variant.medicine_reference_name }}
              </div>
            </div>
            <a-divider style="margin: 18px 0;" />
            <div style="display: flex">
              <div style="margin-right: 40px">
                <div class="label">Satuan</div>
                <div class="sub-label">
                  {{
                    variantData.variant.attributes &&
                      variantData.variant.attributes.filter(el => el.name === "unit")[0].pivot.value
                  }}
                </div>
              </div>
              <div style="padding-left: 40px; border-left: solid 2px #e5e5e6;">
                <div class="label">Isi</div>
                <div class="sub-label">
                  <span>
                    {{
                      variantData.variant.attributes &&
                        variantData.variant.attributes.filter(el => el.name === "amount")[0].pivot
                          .value
                    }}
                  </span>
                  <span>
                    {{
                      variantData.variant.attributes &&
                        variantData.variant.attributes.filter(el => el.name === "amount_unit")[0]
                          .pivot.value
                    }}
                  </span>
                </div>
              </div>
            </div>
            <a-divider style="margin: 18px 0;" />
            <div>
              <div class="label">Paten</div>
              <div class="sub-label">
                {{
                  variantData.variant.attributes &&
                    variantData.variant.attributes.filter(el => el.name === "patent")[0].pivot.value
                }}
              </div>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
  </a-spin>
</template>

<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import { Portal } from "portal-vue";
import gql from "graphql-tag";
import { message } from "ant-design-vue";
import moment from "moment";
import { fullMonthNames } from "@/helpers/utils";
import PusherConnection from "@/helpers/pusherConfig";

const columnTable = [
  {
    index: 0,
    title: "Tanggal",
    dataIndex: "date",
    key: "date",
  },
  {
    index: 1,
    title: "Awal",
    dataIndex: "beginning",
    key: "beginning",
    sorter: false,
  },
  {
    index: 2,
    title: "Terjual",
    dataIndex: "sold",
    key: "sold",
    sorter: false,
  },
  {
    index: 3,
    title: "Retur",
    dataIndex: "return",
    key: "return",
    sorter: false,
  },
  {
    index: 4,
    title: "Perubahan",
    dataIndex: "adjustment",
    key: "adjustment",
    sorter: false,
  },
  {
    index: 5,
    title: "Akhir",
    dataIndex: "ending",
    key: "ending",
    sorter: false,
  },
];

const STORE_VARIANT = gql`
  query fetchStoreAndVariant($id: Uuid!, $variantId: Uuid!, $date: DateRange!) {
    store(id: $id) {
      id
      name
      address
      contact_phone
      opening_time
      closing_time
      is_active
    }
    variantStore(store_id: $id, variant_id: $variantId) {
      id
      store_id
      variant_id
      price
      quantity
      is_active
      variant {
        name
        sku
        description
        medicine_reference_id
        medicine_reference_name
        attributes {
          id
          name
          pivot {
            value
          }
        }
        photos {
          id
          variant_photo
        }
      }
    }
    dailyInventories(store_id: $id, variant_id: $variantId, date: $date) {
      id
      date
      beginning
      sold
      lost
      adjustment_positive
      adjustment_negative
      ending
    }
  }
`;

export default Vue.extend({
  data() {
    return {
      variantPrice: "",
      variantQty: "",
      variantIsActive: "",
      imgPreviewStyle: {
        backgroundColor: "#e5e5e6",
        width: "100px",
        height: "55px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      storeData: {},
      variantData: {},
      dailyInventoriesData: [],
      columnTable,
      locale: {
        emptyText: "Tidak Ada Data",
      },
      isLoading: 0,
      isLoadingUpdate: false,
      isLoadingFull: false,
      isTableLoading: false,
      isFirst: true,
      listMonthYear: [],
      currentMthYr: "",
      startMthYr: null,
      endMthYr: null,
    };
  },
  props: {
    storeId: { type: String },
    variantId: { type: String },
    name: { type: String },
  },
  components: {
    Portal,
  },
  apollo: {
    fetchStoreAndVariant: {
      query() {
        this.isLoadingFull = true;
        return STORE_VARIANT;
      },
      variables() {
        return {
          id: this.storeId,
          variantId: this.variantId,
          date: {
            from: this.startMthYr || moment().format("YYYY-MM-01"),
            to: this.endMthYr || moment().format("YYYY-MM-31"),
          },
        };
      },
      update(data) {
        this.storeData = { ...data.store };
        const tmpVariantData = { ...data.variantStore };
        tmpVariantData.price = String(tmpVariantData.price);
        tmpVariantData.quantity = String(tmpVariantData.quantity);
        tmpVariantData.is_active = tmpVariantData.is_active ? "Aktif" : "Tidak Aktif";
        this.variantData = { ...tmpVariantData };
        this.dailyInventoriesData = [...data.dailyInventories];
      },
      result() {
        this.isLoadingFull = false;
        this.isFirst = false;
      },
      error(error) {
        console.error("apollo query error: ", error);
      },
      fetchPolicy: "no-cache",
    },
  },
  computed: {
    ...mapGetters("StockProductStore", ["getDailyInventories", "getVariant", "getUpdatedStock"]),
    getDailyInbentoriesComputed() {
      const dailyInventories = this.dailyInventoriesData.map((item, index) => {
        const tmp = { ...item };
        tmp.index = index + 1;
        tmp.return = "0";
        tmp.adjustment = `${tmp.adjustment_positive - tmp.adjustment_negative}`;
        return tmp;
      });
      const updStock = this.getUpdatedStock;
      if (Object.keys(updStock).length > 0) {
        const idxUpd = dailyInventories.findIndex((el) => el.id === updStock.id);
        const idx = dailyInventories[idxUpd].index;
        dailyInventories[idxUpd] = updStock;
        dailyInventories[idxUpd].index = idx;
        dailyInventories[idxUpd].return = "0";
        dailyInventories[idxUpd].adjustment = `${updStock.adjustment_positive - updStock.adjustment_negative}`;
        this.isTableLoading = false; // eslint-disable-line
      }
      return this.isFirst ? [] : dailyInventories;
    },
  },
  beforeDestroy() {
    window.pusher.disconnect();
  },
  methods: {
    ...mapActions("StockProductStore", ["fetchDailyInventories"]),
    isNumber(event) {
      if (!/\d/.test(event.key)) {
        return event.preventDefault();
      }

      return true;
    },
    isDecimal(event) {
      const keyCode = event.keyCode ? event.keyCode : event.which;
      // only allow number and one dot
      if (
        (keyCode < 48 || keyCode > 57)
        && (keyCode !== 46 || this.variantData.price.indexOf(".") !== -1)
      ) {
        // 46 is dot
        return event.preventDefault();
      }

      // restrict to 2 decimal places
      if (
        this.variantData.price !== ""
        && this.variantData.price.indexOf(".") > -1
        && this.variantData.price.split(".")[1].length > 1
        && event.target.selectionStart > this.variantData.price.indexOf(".")
      ) {
        return event.preventDefault();
      }

      return true;
    },
    checkFormValid() {
      return this.variantData.price === "" || this.variantData.quantity === "";
    },
    handleMthYrChange(value) {
      this.currentMthYr = value;
      this.refetchDailyInventories();
    },
    refetchDailyInventories() {
      let startMthYr = "";
      let endMthYr = "";

      const monthYear = this.currentMthYr.split(" ");
      const idx = fullMonthNames.findIndex((name) => name === monthYear[0]);

      const endDateInMonth = new Date(Number(monthYear[1]), idx + 1, 0).getDate();

      startMthYr = `${monthYear[1]}-${idx + 1}-1`;
      endMthYr = `${monthYear[1]}-${idx + 1}-${endDateInMonth}`;

      const options = {
        id: this.storeId,
        variantId: this.variantId,
        date: {
          from: startMthYr,
          to: endMthYr,
        },
      };
      this.isTableLoading = true;
      this.fetchDailyInventories(options)
        .then((data) => {
          this.isTableLoading = false;
          this.dailyInventoriesData = [...data.dailyInventories];
        })
        .catch((error) => {
          this.isTableLoading = false;
          console.error("error re-fetching dailyInventories: ", error);
        });
    },
    handleUpdate(e) {
      e.preventDefault();
      this.isLoadingUpdate = true;
      const payload = {
        store_id: this.storeId,
        variant_id: this.variantId,
        price: Number(this.variantData.price),
        is_active: this.variantData.is_active === "Aktif",
        input: {
          qty_ending: Number(this.variantData.quantity),
          variant_id: this.variantId,
        },
      };
      this.$apollo
        .mutate({
          mutation: gql`
            mutation updateVariantStoreQuantity(
              $store_id: Uuid!
              $variant_id: Uuid!
              $price: Float!
              $is_active: Boolean!
              $input: UpdateQuantityVariantStoreInput!
            ) {
              updateVariantStore(
                store_id: $store_id
                variant_id: $variant_id
                price: $price
                is_active: $is_active
              ) {
                id
                store_id
                variant_id
                price
                quantity
                is_active
                variant {
                  name
                  sku
                  description
                  medicine_reference_id
                  medicine_reference_name
                  attributes {
                    id
                    name
                    pivot {
                      value
                    }
                  }
                  photos {
                    id
                    variant_photo
                  }
                }
              }
              updateQuantityVariantStore(store_id: $store_id, input: $input)
            }
          `,
          variables: {
            store_id: payload.store_id,
            variant_id: payload.variant_id,
            price: payload.price,
            is_active: payload.is_active,
            input: payload.input,
          },
        })
        .then(() => {
          message.success("Data stok produk berhasil diubah");
          this.isLoadingUpdate = false;
          this.isTableLoading = false;
        })
        .catch((error) => {
          console.log("error updating variant Store: ", error);
          message.error("Gagal mengubah data stok produk, mohon ulangi");
          this.isLoadingUpdate = false;
        });
    },
  },
  created() {
    this.$emit("setRightSidebarVisible", false);
  },
  mounted() {
    this.$store
      .dispatch("StockProductStore/subscribeDailyInventory", {
        store_id: this.storeId,
        variant_id: this.variantId,
      })
      .then((resp) => {
        resp.subscribe((event) => {
          window.pusher = new PusherConnection(
            event.extensions.lighthouse_subscriptions.channel,
            this.$store,
          );
        });
      });

    moment.locale("id");
    const currentDate = new Date();
    const mthName = moment(currentDate).format("MMMM");
    const mth = currentDate.getMonth() + 1;
    const yr = currentDate.getFullYear();

    this.currentMthYr = `${mthName} ${yr}`;

    for (let i = 2021; i <= yr; i += 1) {
      for (let j = 1; j <= 12; j += 1) {
        if (`${i}${j}` >= "20213" && `${i}${j}` <= `${yr}${mth}`) {
          this.listMonthYear.push(`${moment().month(j - 1).format("MMMM")} ${i}`);
        }
      }
    }
  },
});
</script>

<style lang="scss">
.stock-container {
  .ant-input-affix-wrapper .ant-input:not(:first-child) {
    padding-left: 46px;
  }
}

.p-select-status-update-stock.ant-select-lg {
  width: 138px;
  color: #0146ab;

  .ant-select-selection--single {
    border-radius: 24px;
    border: 1px solid #0146ab;
    background: rgba(204, 218, 238, 0.6);
  }
}

.p-select-status-update-stock.not-active-status.ant-select-lg {
  width: 138px;
  color: #ff6e5d;

  .ant-select-selection--single {
    border-radius: 24px;
    border: 1px solid #ff6e5d;
    background: rgba(255, 226, 222, 0.6);
  }
}
</style>

<style lang="scss" scoped>
.p-stock-header {
  .header-btn-save {
    border-radius: 26px;
    border: none;
    font-size: 18px;
    font-weight: bold;
    width: 205px;

    &:focus {
      background: #1f64b8;
    }

    &:active {
      background: #0146ab;
    }
  }
}

.label-field {
  color: #4c4f54;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 8px;
}

.stock-container {
  background: #fff;
  border: solid 1px #e5e5e6;
  border-radius: 16px;
  padding: 16px;
  margin-bottom: 16px;

  .variant-name {
    padding-bottom: 8px;
    font-size: 24px;
    font-weight: 700;
    color: #0146ab;
    display: flex;
    align-items: center;
  }
}

.variant-history {
  background: #fff;
  border: solid 1px #e5e5e6;
  border-radius: 16px;
  padding: 16px;
  margin-bottom: 16px;

  .title {
    font-size: 24px;
    font-weight: 700;
    color: #0146ab;
    display: flex;
    align-items: center;
  }
}

.variant-info {
  background: #fff;
  border: solid 1px #e5e5e6;
  border-radius: 16px;
  padding: 16px;
  margin-bottom: 16px;

  .label {
    font-size: 16px;
    font-weight: 700;
    color: #4c4f54;
    margin-bottom: 8px;
  }

  .sub-label {
    font-size: 14px;
    font-weight: 400;
    color: rgb(127, 129, 132);
  }
}
</style>
